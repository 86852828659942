import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=1e26846e&scoped=true&lang=pug"
import script from "./Error404.vue?vue&type=script&lang=coffee"
export * from "./Error404.vue?vue&type=script&lang=coffee"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_pug@3.0.2_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e26846e",
  null
  
)

export default component.exports