import { render, staticRenderFns } from "./caracteristicas.vue?vue&type=template&id=02f091dc&scoped=true&lang=pug"
import script from "./caracteristicas.vue?vue&type=script&lang=coffee"
export * from "./caracteristicas.vue?vue&type=script&lang=coffee"
import style0 from "./caracteristicas.vue?vue&type=style&index=0&id=02f091dc&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_pug@3.0.2_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f091dc",
  null
  
)

export default component.exports